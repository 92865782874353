<script setup lang="ts">
import iconUrl from './fss-icon.webp';
import defaultLogoUrl from './Logo.svg';
import darkLogoUrl from './Logo-dark.svg';
import darkRedDividerLogoUrl from './Logo-dark-red-divider.svg';
import { computed } from 'vue';

    // Configuration
    const props = defineProps<{
        mode?: undefined | 'dark',
        divider?: undefined | 'red',
        iconOnly?: boolean
    }>();

    // Setup
    const imageUrl = computed(() => {
        if(props.iconOnly === true) {
            return iconUrl;
        }
        if (props.mode === 'dark') {
            return props.divider === 'red' ? darkRedDividerLogoUrl : darkLogoUrl;
        }
        return defaultLogoUrl;
    });

</script>


<template>

    <img :src="imageUrl"
        alt="Fire Station Software Logo"
        class="select-none"
    />

</template>
